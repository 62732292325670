module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"53","matomoUrl":"https://stats.waag.org/","siteUrl":"https://openschoolingnavigator.coruna.eu/","dev":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Open Schooling Navigator","short_name":"mio-osn","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"776c78859de193bf6cb8af7b269e1bb1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl","he","sv","ar","pl","hu","pt-pt","el","es","gl","ro","en"],"defaultLanguage":"en","fallbackLanguage":"en","getLanguageFromPath":true,"redirect":false,"siteUrl":"https://openschoolingnavigator.coruna.eu","pages":[{"matchPath":"/:lang?/open-schooling-materials","getLanguageFromPath":true},{"matchPath":"/:lang?/favourites$","getLanguageFromPath":true},{"matchPath":"/:lang?/build-your-own$","getLanguageFromPath":true},{"matchPath":"/:lang?/build-your-own/:id?","getLanguageFromPath":true},{"matchPath":"/:lang?/learning-scenario/:title","getLanguageFromPath":true},{"matchPath":"/:lang?/learning-unit/:title","getLanguageFromPath":true},{"matchPath":"/share/:data?","languages":["en"]},{"matchPath":"/:lang?/:page?","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
